<template>
  <!-- <b-card no-body>
    <b-card-header>
      <b-card-title class="mb-50"> {{ title }} </b-card-title>
    </b-card-header>
    <b-card-body>
      
    </b-card-body>
  </b-card> -->
  <div ref="FundsRate" :style="{ width: '100%', height: '300px' }"></div>
</template>
<script>
import * as echarts from "echarts";
import { BCard, BCardBody, BCardHeader, BCardTitle } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    VueApexCharts,
    flatPickr,
    BCardTitle,
  },
  props: {
    // 动态传递一个数据
    series: {
      type: Array,
    },
    // 动态传递一个title
    // title: {
    //   type: String,
    // },
    chartType: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      chart: null,
      FundsRate: null,
      cMargindataMap: [], // 数据存放位置
      uMargindataMap: [], //
      exchangeData: [], // 图例存放位置
      title2: "",
      series1: [
        {
          name: "cMargin",
          type: "bar",
          // data:this.cMargindataMap,
          data: [-0.001, 0.0252, 0.024, 0.01, 0.24, 0.14],
          // 标记线
          markLine: {
            data: [
              {
                name: "标准线",
                xAxis: 0.01,
              },
            ],
          },
          itemStyle: {
            normal: {
              color: function (params) {
                var index_color = params.value;

                if (index_color > 0.01) {
                  return "#ea5455";
                } else if (index_color === 0.01) {
                  return "rgb(98,86,238)";
                } else {
                  return "#28c76f";
                }
              },
            },
          },
        },
        {
          name: "uMargin",
          type: "bar",
          // data:this.uMargindataMap,
          data: [0.0352, 0.0352, -0.001, 0.0258, 0, 0],
          itemStyle: {
            normal: {
              color: function (params) {
                var index_color = params.value;
                if (index_color > 0.01) {
                  return "#ea5455";
                } else if (index_color === 0.01) {
                  return "rgb(98,86,238)";
                } else {
                  return "#28c76f";
                }
              },
            },
          },
        },
      ],
      series2: [
        {
          name: "ETH",
          type: "bar",
          // data:this.cMargindataMap,
          data: [-0.001, 0.0252, 0.024, 0.01, 0.24, 0.14],
          // 标记线
          markLine: {
            data: [
              {
                name: "标准线",
                xAxis: 0.01,
              },
            ],
          },
          itemStyle: {
            normal: {
              color: function (params) {
                var index_color = params.value;

                if (index_color > 0.01) {
                  return "#ea5455";
                } else if (index_color === 0.01) {
                  return "rgb(98,86,238)";
                } else {
                  return "#28c76f";
                }
              },
            },
          },
        },
        {
          name: "uMargin",
          type: "bar",
          // data:this.uMargindataMap,
          data: [0.0352, 0.0352, -0.001, 0.0258, 0.01, 0.24],
          itemStyle: {
            normal: {
              color: function (params) {
                var index_color = params.value;
                if (index_color > 0.01) {
                  return "#ea5455";
                } else if (index_color === 0.01) {
                  return "rgb(98,86,238)";
                } else {
                  return "#28c76f";
                }
              },
            },
          },
        },
      ],
    };
  },
  mounted() {
    //屏幕自适应监听
    // console.log("echart", echarts);
    this.getEchartData();
    // this.initChart();
    window.addEventListener("resize", this.chart.resize) 
  },
  methods: {
    
    initChart(){
      if(!this.barChart ){
        this.barChart = echarts.init(this.$refs.FundsRate);
      }
      window.addEventListener("resize", this.barChart.resize) 
    },

    getEchartData() {
      const chart = this.$refs.FundsRate;
      this.chart = echarts.init(chart);
      const option = {
        title: {
          text: "ETC",
        },
        tooltip: {
          trigger: "axis",
          borderWidth: "0", //边框宽度设置1
          borderColor: "none", //设置边框颜色
          formatter: (params) => {
            let result = `<div style="border-bottom: 1px solid rgba(151, 151, 151, 0.2); padding-bottom: 8px; margin-bottom: 8px">
                    ${params[0].name}
                  </div>`;

            params.forEach((item) => {
              var aaa = parseFloat(item.value).toFixed(2);
              result += `<span style="">${item.seriesName} : ${aaa}%</span></br>`;
            });
            return result;
          },
          padding: 20,
          textStyle: {
            fontSize: 12,
            color: "rgba(209, 212, 220, 1)", //设置文字颜色
          },
          tooltip: {
            show: true,
          },
          extraCssText: "background:rgba(61, 63, 71, 1);",
        },
        // legend: {},
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: "category",
          data: this.exchangeData,
        },
         graphic: [
          {
            type: "image",
            id: "logo",
            right: "center",
            bottom: "center",
            z: 0,
            bounding: "all",
            style: {
              image:
                "https://apilib.rta.academy/media/2023/07/26/echarts-bg_yYgTFo1.png", // 必须是https开头的图片路径地址
              width: 200,
              height: 34,
            },
          },
        ],
        series: [
          {
            name: "2011",
            type: "bar",
            data: [],
          },
          {
            name: "2012",
            type: "bar",
            data: [],
          },
        ],
      };
      
      this.chart.setOption(option);
    },
  },
};
</script>

<style lang="scss">
</style>
