<template>
  <div
    ref="SortDataInBarChart"
    :style="{ width: '100%', height: '300px' }"
  ></div>
</template>
<script>
import * as echarts from "echarts";
import { BCard, BCardBody, BCardHeader, BCardTitle } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    VueApexCharts,
    flatPickr,
    BCardTitle,
  },
  props: {
    // 动态传递一个数据
    series: {
      type: Array,
    },
    // 动态传递一个title
    title: {
      type: String,
    },
  },
  data() {
    return {
      chart: null,
      fundingRatedataMap: [], // 数据存放位置
      binanceData: [], // 币种存放位置
    };
  },
  mounted() {
    // console.log("echart", echarts);
    this.getEchartData();
    window.addEventListener("resize", this.chart.resize);
  },
  methods: {
    getEchartData() {
      const chart = this.$refs.SortDataInBarChart;
      this.chart = echarts.init(chart);
      const option = {
        title: {
          text: "Binance",
          textStyle: {
            color: "rgb(159,161,167)",
          },
        },
        tooltip: {
          trigger: "axis",
          borderWidth: "0", //边框宽度设置1
          borderColor: "none", //设置边框颜色
          formatter: (params) => {
            let result = `<div style="border-bottom: 1px solid rgba(151, 151, 151, 0.2); padding-bottom: 8px; margin-bottom: 8px">
                    ${params[0].name}
                  </div>`;

            params.forEach((item) => {
              result += `<span style="">${item.seriesName} :${item.value}</span></br>`;
            });
            return result;
          },
          padding: 20,
          textStyle: {
            fontSize: 12,
            color: "rgba(209, 212, 220, 1)", //设置文字颜色
          },
          tooltip: {
            show: true,
          },
          extraCssText: "background:rgba(61, 63, 71, 1);",
        },
        xAxis: {
          type: "category",
          axisLabel: { interval: 0, rotate: 30 },
          data: [],
          max: 20, // 最多显示20个币种
        },
        yAxis: {
          splitLine: { show: false }, //去除网格线
        },
        series: [
          {
            name: "last_funding_rate",
            type: "bar",
            data: [],
            barCategoryGap: "0%", //柱形间距

            // markPoint: {
            //   data: [
            //     { name: "BTC", xAxis: " BTC" },
            //     { name: "ETH", xAxis: "ETH" },
            //   ],
            // },
            datasetIndex: 1, //自动排序
          },
        ],
        graphic: [
          {
            type: "image",
            id: "logo",
            right: "center",
            bottom: "center",
            z: 0,
            bounding: "all",
            style: {
              image:
                "https://apilib.rta.academy/media/2023/07/26/echarts-bg_yYgTFo1.png", // 必须是https开头的图片路径地址
              width: 192,
              height: 32,
            },
          },
        ],
      };
      this.chart.setOption(option);
    },
  },
};
</script>

<style lang="scss">
</style>
