import request from "@/utils/request"
// //获取brr数据
// export function getBrData() {
//     return request({
//         method: 'get',
//         url: '/CmeWS/mvc/Bitcoin/ALLRR',
//         params: {
//             //json格式，如果有就写，没有就去掉。
//         }

//     })

// }

//获取Home资金费率图表数据
export function getFundsRate() {
    return request({
        method: 'get',
        url: '/fundingRate',
        params: {
            //json格式，如果有就写，没有就去掉。
        }

    })

}