<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col lg="6" md="12">
        <b-card class="upLine-card">
          <div class="upLine-bg">
            <div class="upLine-tittle">RTA 数据站</div>
            <span>从交易者的角度出发，为交易员提供专业的数据</span>
          </div>
          <!-- <analytics-congratulation :data="data.congratulations" /> -->
        </b-card>
      </b-col>

      <b-col lg="3" sm="6">
        <b-card no-body>
          <b-card-header>
            <b-card-title class="acronym"> BRR </b-card-title>
          </b-card-header>
          <b-card-body>
            <div class="currencies-reference">
              <b>Reference Rate</b>
            </div>
            <div class="currencies-values">
              <div class="date-value">
                <!-- $<b class="valueShow">{{ brrData[0].value.toFixed(2) }}</b> -->
                $<b class="valueShow">{{ brrData[0].value }}</b>
              </div>
              <div class="date-line">
                Last Updated
                <div class="date">
                  {{ convertToUTC8(brrData[0].date) }}
                </div>
              </div>
            </div>

            <div class="currencies-historical">
              <div class="historical-dates">
                <div class="historical-date">
                  {{ formatDateShow(brrData[1].date) }}
                  <span class="valueShow">
                    <!-- ${{ brrData[1].value.toFixed(2) }} -->
                  </span>
                </div>
              </div>
              <div class="historical-dates">
                <div class="historical-date">
                  {{ formatDateShow(convertToUTC8(brrData[2].date)) }}
                  <span class="valueShow">
                    <!-- ${{ brrData[1].value.toFixed(2) }} -->
                    ${{ brrData[1].value }}
                  </span>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col lg="3" sm="6">
        <b-card no-body>
          <b-card-header>
            <b-card-title class="acronym"> BRTI </b-card-title>
          </b-card-header>
          <b-card-body>
            <div class="currencies-reference">
              <b>Real-Time Index</b>
            </div>
            <div class="currencies-values">
              <div class="date-value">
                <b>${{ brtiData.value.toFixed(2) }}</b>
              </div>
              <div class="date-line">
                Last Updated
                <div class="date">{{ convertToUTC8(brtiData.date) }}</div>
              </div>
            </div>
            <div class="currencies-historical"></div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col lg="6">
        <b-card no-body>
          <b-card-header>
            <b-card-title class="fundsRate">BTC平值期权隐含波动率
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <Chart ref="chart" class="chart" :option="assetsOption" :loading="loading1" />
          </b-card-body>
        </b-card>
      </b-col>

      <b-col lg="6">
        <b-card no-body>
          <b-card-header>
            <b-card-title class="fundsRate">BTC持仓数据 </b-card-title>
          </b-card-header>
          <b-card-body>
            <Chart id="chart" ref="chart" class="chart" :option="earningOption" :loading="loading2" />
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="carousel">
      <b-col lg="12">
        <b-card no-body>
          <b-card-header>
            <b-card-title class="fundsRate"> Deribit BTC大宗交易 </b-card-title>
          </b-card-header>
          <b-card-body class="tabletrade">
            <el-table v-if="tableData.length > 0" :data="tableData" :row-style="{ height: '55px' }"
              style="width: 100%; background-color: transparent" height="520" class="table-trade"
              :row-class-name="tableRowClassName">
              <el-table-column prop="instrument_name" label="标的名">
              </el-table-column>
              <el-table-column prop="direction" label="交易方向">
              </el-table-column>
              <el-table-column prop="timestamp" label="交易时间">
              </el-table-column>
              <el-table-column prop="amount" label="成交数量">
              </el-table-column>
              <el-table-column prop="price" label="成交价"> </el-table-column>
              <el-table-column prop="index_price" label="指数价格" :formatter="matter">
              </el-table-column>
              <el-table-column prop="iv" label="IV"> </el-table-column>
              <el-table-column prop="price_amount" label="交易额(USD)" :formatter="matter">
              </el-table-column>
            </el-table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
  import { getBrData, getFundsRate } from "@/api/Home.js";

  import Chart from "@/views/charts-and-maps/charts/chart.vue";
  import BarCharts from "@/views/charts-and-maps/charts/barCharts.vue";
  import {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
  } from "bootstrap-vue";
  import AnalyticsCongratulation from "./AnalyticsCongratulation.vue";
  import AnalyticsAvgSessions from "./AnalyticsAvgSessions.vue";
  import AnalyticsSupportTracker from "./AnalyticsSupportTracker.vue";
  import AnalyticsTimeline from "./AnalyticsTimeline.vue";
  import AnalyticsSalesRadarChart from "./AnalyticsSalesRadarChart.vue";
  import AnalyticsAppDesign from "./AnalyticsAppDesign.vue";
  import FundsRate from "@/views/charts-and-maps/charts/echart/option-echart/FundsRate.vue";
  import SortDataInBarChart from "@/views/charts-and-maps/charts/echart/option-echart/SortDataInBarChart.vue";
  import { getBtcOptionsRate } from "@/api/BlockTransactions.js";
  export default {
    components: {
      BCard,
      BCardBody,
      BCardHeader,
      BCardTitle,
      BRow,
      BCol,
      AnalyticsCongratulation,
      AnalyticsAvgSessions,
      AnalyticsSupportTracker,
      AnalyticsTimeline,
      AnalyticsSalesRadarChart,
      AnalyticsAppDesign,
      // InvoiceList,
      FundsRate,
      SortDataInBarChart,
      Chart,
      BarCharts,
    },
    data() {
      return {
        tableData: [],
        pageParams: {
          currentPage: 1,
          pageSize: 20,
          total: 0,
        },
        brrData: [
          {
            value: 0,
            data: "",
          },
          {
            value: 0,
            data: "",
          },
          {
            value: 0,
            data: "",
          },
        ],
        brtiData: {
          value: 0,
          data: "",
        },
        finalufundingRateArr: [],
        exchangeData: [], //yAxis data交易所名称
        initChartData: [], //存放初始图表数据
        currencyData: [], //存放binance所有货币
        binanceData: [], //存放binance数据
        btcData: [],
        ethData: [],
        dataMap: [], //各个交易所得资金费率
        dataList: [], // 存储数据列表信息
        value: "61672.11111111",
        date: "2021-10-11 15:01:01",
        data: {
          congratulations: {
            name: "John",
            saleToday: "57.6",
          },
          subscribersGained: {
            series: [
              {
                name: "Subscribers",
                data: [28, 40, 36, 52, 38, 60, 55],
              },
            ],
            analyticsData: {
              subscribers: 92600,
            },
          },
          avgSessions: {
            sessions: 2700,
            lastDays: ["Last 28 Days", "Last Month", "Last Year"],
            growth: "+5.2%",
            goal: 100000,
            users: 100000,
            retention: 90,
            duration: 1,
            salesBar: {
              series: [
                {
                  name: "Sessions",
                  data: [75, 125, 225, 175, 125, 75, 25],
                },
              ],
            },
          },
          supportTracker: {
            title: "Support Tracker",
            lastDays: ["Last 28 Days", "Last Month", "Last Year"],
            totalTicket: 163,
            newTicket: 29,
            openTicket: 63,
            responseTime: 1,
            supportTrackerRadialBar: {
              series: [83],
            },
          },
          timeline: {
            step1: {
              title: "12 Invoices have been paid",
              subtitle: "Invoices have been paid to the company.",
              img: require("@/assets/images/icons/json.png"),
              fileName: "data.json",
              duration: "12 min ago",
            },
            step2: {
              title: "Client Meeting",
              subtitle: "Project meeting with john @10:15am",
              avatar: require("@/assets/images/portrait/small/avatar-s-9.jpg"),
              avatarName: "John Doe (Client)",
              occupation: "CEO of Infibeam",
              duration: "45 min ago",
            },
            step3: {
              title: "Create a new project for client",
              subtitle: "Add files to new design folder",
              duration: "2 day ago",
              avatars: [
                {
                  userImg: require("@/assets/images/portrait/small/avatar-s-9.jpg"),
                  name: "Billy Hopkins",
                },
                {
                  userImg: require("@/assets/images/portrait/small/avatar-s-6.jpg"),
                  name: "Amy Carson",
                },
                {
                  userImg: require("@/assets/images/portrait/small/avatar-s-8.jpg"),
                  name: "Brandon Miles",
                },
                {
                  userImg: require("@/assets/images/portrait/small/avatar-s-7.jpg"),
                  name: "Daisy Weber",
                },
                {
                  userImg: require("@/assets/images/portrait/small/avatar-s-20.jpg"),
                  name: "Jenny Looper",
                },
              ],
            },
            step4: {
              title: "Create a new project for client",
              duration: "5 day ago",
              subtitle: "Add files to new design folder",
            },
          },
          salesChart: {
            series: [
              {
                name: "Sales",
                data: [90, 50, 86, 40, 100, 20],
              },
              {
                name: "Visit",
                data: [70, 75, 70, 76, 20, 85],
              },
            ],
          },
          appDesign: {
            date: "03 Sep, 20",
            title: "App design",
            subtitle:
              "You can Find Only Post and Quotes Related to IOS like ipad app design, iphone app design",
            teams: [
              { name: "Figma", color: "light-warning" },
              { name: "Wireframe", color: "light-primary" },
            ],
            members: [
              {
                img: require("@/assets/images/portrait/small/avatar-s-9.jpg"),
                color: "primary",
              },
              { text: "PI", color: "light-danger" },
              {
                img: require("@/assets/images/portrait/small/avatar-s-14.jpg"),
                color: "primary",
              },
              {
                img: require("@/assets/images/portrait/small/avatar-s-7.jpg"),
                color: "primary",
              },
              { text: "AL", color: "light-secondary" },
            ],
            planing: [
              { title: "Due Date", subtitle: "12 Apr, 21" },
              { title: "Budget", subtitle: "$49251.91" },
              { title: "Cost", subtitle: "$840.99" },
            ],
          },
        },
        assetsOption: {},
        oneMonthIv: [],
        threeMonthIv: [],
        sixMonthIv: [],
        ivTime: [],
        earningOption: {},
        callInfos: {
          open_sum: [],
          option_price: [],
          value: [],
        },
        putInfos: {
          open_sum: [],
          option_price: [],
          value: [],
        },
        loading1: true,
        loading2: true,
      };
    },
    created() {
      this.findVolatility();
      this.findProfitForm();
      this.getBrData(); //执行请求
      this.initWebSocket();
      this.getRateMap();
      this.getListData();
      // this.getFundsRate();
      //this.getstageData();
      this.getVolatilityData();
      this.getPainpointData();
    },
    computed: {
      getBTCdata() {
        let index = this.binanceData.indexOf("BTC");
        let data = this.finalufundingRateArr[index];
        // console.log("getBTCdata", data);
        return data;
      },
      getETHdata() {
        let index = this.binanceData.indexOf("ETH");
        let data = this.finalufundingRateArr[index];
        // console.log("getETHdata", data);
        return data;
      },
    },
    mounted() { },
    methods: {
      convertToUTC8(dateString) {
        // 创建一个 Date 对象，并指定 UTC 时间
        const utcDate = new Date(dateString + ' UTC');
        // 使用 toLocaleString 方法转换为中国标准时间，并格式化输出
        const options = {
          timeZone: 'Asia/Shanghai',
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: false
        };
        return utcDate.toLocaleString('zh-CN', options).replace(/\//g, '-');
      },
      //获取平值期权隐含波动率全部数据
      getVolatilityData() {
        const params = {
          currency: "BTC",
          timeType: "year",
        };
        this.axios
          .get("https://trade.rta-finance.com/trade/deribitiv/ivinfo/", {
            params,
          })
          .then((res) => {
            if (res.data.code == "0") {
              this.isRepetition = false;
              let tempData = res.data.data;
              this.oneMonthIv = tempData.map((v) =>
                parseFloat(v.one_month_iv).toFixed(2)
              );
              this.threeMonthIv = tempData.map((v) =>
                parseFloat(v.three_month_iv).toFixed(2)
              );
              this.sixMonthIv = tempData.map((v) =>
                parseFloat(v.six_month_iv).toFixed(2)
              );

              this.ivTime = tempData.map((v) =>
                this.dateTypeFormat(
                  "YYYY-mm-dd HH:MM",
                  new Date(v.day ? v.day : v.time)
                )
              );

              this.findVolatility();
            } else {
              this.oneMonthIv = [];
              this.threeMonthIv = [];
              this.sixMonthIv = [];
              this.ivTime = [];
            }
          })
          .catch((err) => { })
          .finally(() => {
            this.loading1 = false;
          });
      },
      //比特币平值期权期限结构曲线
      findVolatility() {
        const xData = this.ivTime;
        const ayData = this.oneMonthIv;
        const byData = this.threeMonthIv;
        const cyData = this.sixMonthIv;
        const seriesData = [
          {
            name: "1 month",
            data: ayData,
            type: "line",
            symbol: "circle",
            showSymbol: false,
            lineStyle: {
              color: "rgba(234, 204, 174, 1)",
              width: 1.5,
            },
            itemStyle: {
              normal: {
                color: "rgba(234, 204, 174, 1)",
              },
            },
          },
          {
            name: "3 month",
            data: byData,
            type: "line",
            symbol: "circle",
            showSymbol: false,
            lineStyle: {
              color: "rgba(255, 82, 96, 1)",
              width: 1.5,
            },

            itemStyle: {
              normal: {
                color: "rgba(255, 82, 96, 1)",
              },
            },
          },
          {
            name: "6 month",
            data: cyData,
            type: "line",
            symbol: "circle",
            showSymbol: false,
            lineStyle: {
              color: "rgba(74, 181, 232, 1)",
              width: 1.5,
            },
            itemStyle: {
              normal: {
                color: "rgba(74, 181, 232, 1)",
              },
            },
          },
        ];
        this.assetsOption = {
          backgroundColor: "#33353e",
          color: "rgba(0, 174, 147, 1)",
          tooltip: {
            trigger: "axis",
            borderWidth: "0",
            borderColor: "none",
            formatter: (params) => {
              let result = `<div style="border-bottom: 1px solid rgba(151, 151, 151, 0.2); padding-bottom: 8px; margin-bottom: 8px">
                    ${params[0].name}
                  </div>`;
              params.forEach((item) => {
                var re = item.seriesName;
                var colors = "";
                switch (re) {
                  case "1 month":
                    colors = "rgba(234, 204, 174, 1)";
                    break;
                  case "3 month":
                    colors = "rgba(255, 82, 96, 1)";
                    break;
                  case "6 month":
                    colors = "rgba(74, 181, 232, 1)";
                    break;
                }
                var aaa = this.formatNum(item.data);
                result += `<span style="color:${colors} ;">${item.seriesName} : ${aaa}</span><br/>  `;
              });
              return result;
              // let aaa = this.kmb(params[0].data);
              // let bbb = this.kmb(params[1].data);
              // let ccc = this.kmb(params[2].data);
              // return `
              //     <div>
              //       <div style="border-bottom: 1px solid rgba(151, 151, 151, 0.2); padding-bottom: 8px; margin-bottom: 8px">
              //         ${params[0].name}
              //       </div>
              //       <div><span style="color:#4AB5E8">${params[0].seriesName}:  ${aaa}</span><br/>
              //        <span style="color:#EACCAE">${params[1].seriesName}: ${bbb}</span><br/>
              //       <span style="color:#FF5260">${params[2].seriesName}: ${ccc}</span><br/></div>

              //     </div>
              //   `;
            },
            padding: 20,
            textStyle: {
              fontSize: 12,
              color: "rgba(209, 212, 220, 1)", //设置文字颜色
            },
            tooltip: {
              show: true,
            },
            extraCssText: "background:rgba(61, 63, 71, 1);",
          },
          grid: {
            left: "2%",
            right: "2%",
            bottom: "5%",
            top: 80,
            containLabel: true,
          },
          legend: {
            top: 20,
            textStyle: {
              fontSize: 12,
              color: "#D1D4DC",
            },
            padding: [0, 32, 0, 0],
            itemHeight: 4,
            itemWidth: 12,
            itemGap: 40,
            data: [
              {
                name: "1 month",
                icon: "rect",
              },
              {
                name: "3 month",
                icon: "rect",
              },
              {
                name: "6 month",
                icon: "rect",
              },
            ],
            formatter: function (name) {
              // 在这里添加括号和对应的 value 值加 '%'
              var value = ""; // 这里根据 name 获取相应的 value 值
              for (var i = 0; i < seriesData.length; i++) {
                if (seriesData[i].name === name) {
                  var data = seriesData[i].data;
                  value = parseFloat(data[data.length - 1]).toFixed(2);
                  break;
                }
              }
              return name + " (" + value + "%)";
            },
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: xData,
            //data: ['2022-09-13', '2022-09-12', '2022-09-11', '2022-09-10', '2022-09-09', '2022-09-08', '2022-09-07'],
            offset: 12,
            axisLabel: {
              textStyle: {
                color: "rgba(209, 212, 220, 1)",
              },
              formatter: function (value) {
                const date = new Date(value);
                const year = date.getFullYear();
                const month = date.getMonth() + 1;
                const day = date.getDate();
                return (
                  year +
                  "-" +
                  (month < 10 ? "0" : "") +
                  month +
                  "-" +
                  (day < 10 ? "0" : "") +
                  day
                );
              },
            },
          },
          yAxis: {
            type: "value",
            min: "dataMin",
            name: "IV（%）",
            splitLine: {
              lineStyle: {
                type: "dashed",
                color: ["rgba(159, 159, 159, 0.1)"],
              },
            },
            axisLabel: {
              axisLabel: {
                formatter: function (value, index) {
                  var rounded = Math.round(value);
                  return rounded;
                },
              },
              textStyle: {
                color: "rgba(209, 212, 220, 1)",
              },
              margin: 20,
            },
          },
          series: seriesData,
          graphic: [
            {
              type: "image",
              id: "logo",
              right: "center",
              bottom: "center",
              z: 0,
              bounding: "all",
              style: {
                image: "echarts-bg_yYgTFo1.png", // 必须是https开头的图片路径地址
                width: 400,
                height: 68,
              },
            },
          ],
        };
      },
      //获取期权痛点全部数据
      getPainpointData() {
        const params = {
          currency: "BTC",
          priceMin: "",
          priceMax: "",
          queryDate: "",
        };
        this.axios
          .get("https://trade.rta-finance.com/trade/deribitoption/painpoint/", {
            params,
          })
          .then((res) => {
            if (res.data.code == "0") {
              this.isRepetition = false;
              let tempData = res.data.data;
              this.priceArr = tempData;
              this.dataOptions = [];
              let dates = tempData.queryDate;
              dates.sort((a, b) => Date.parse(a) - Date.parse(b));
              dates.forEach((item) => {
                const timeArr = this.formatDate(Date.parse(item), "yyyy-MM-dd");

                const positionObj = {
                  value: item,
                  label: timeArr,
                };

                this.dataOptions.push(positionObj);
              });

              // this.dataOptions.sort(object);
              this.dataOptions.unshift({
                value: "all",
                label: "All Expirations",
              });
              this.callInfos.option_price = tempData.callInfos.map(
                (v) => v.option_price
              );
              this.callInfos.open_sum = tempData.callInfos.map((v) =>
                v.open_sum.toFixed(2)
              );
              this.putInfos.open_sum = tempData.putInfos.map((v) =>
                v.open_sum.toFixed(2)
              );
              this.callInfos.Value = tempData.callInfos.map((v) =>
                v.value.toFixed(2)
              );
              this.putInfos.value = tempData.putInfos.map((v) =>
                v.value.toFixed(2)
              );
              this.findProfitForm(this.callInfos.Value, this.putInfos.value);
            } else {
              this.callInfos.option_price = [];
              this.callInfos.open_sum = [];
              this.putInfos.open_sum = [];
            }
          })
          .catch((err) => { })
          .finally(() => {
            this.loading2 = false;
          });
      },
      // BTC持仓数据
      findProfitForm() {
        const xData = this.callInfos.option_price;
        const callSet = this.callInfos.open_sum;
        const putSet = this.putInfos.open_sum;
        const callValue = this.callInfos.value;
        const putValue = this.putInfos.value;
        this.earningOption = {
          backgroundColor: "#33353e",
          tooltip: {
            trigger: "item",
            borderWidth: "0", //边框宽度设置1
            borderColor: "none", //设置边框颜色
            padding: 20,

            textStyle: {
              fontSize: 12,
              color: "rgba(209, 212, 220, 1)", //设置文字颜色
            },

            formatter: (params) => {
              const dataIndex = params.dataIndex; // 获取鼠标移到的柱子的索引
              const seriesName = params.seriesName; // 获取柱子所属系列的名称
              const callValue = this.formatNum(this.callInfos.Value);
              const putValue = this.formatNum(this.putInfos.value);
              const value =
                seriesName === "Calls"
                  ? this.formatNum(callSet[dataIndex])
                  : this.formatNum(putSet[dataIndex]); // 获取柱子的值
              const notionalValue =
                seriesName === "Calls"
                  ? callValue[dataIndex]
                  : putValue[dataIndex]; // 获取柱子的值

              const result = `Strike Price : ${this.formatNum(params.name)}<br>`;
              const notionalV = `Notional Value[USD]: $${this.formatNum(
                notionalValue
              )}<br>`;
              return `
                <div>
                  <div >
                  ${result}${seriesName} : ${value}<br>${notionalV}
                  </div>
                 

                </div>
              `;
            },
            extraCssText: "background:rgba(61, 63, 71, 1);",
          },
          grid: {
            top: 80,
            left: "2%",
            right: "2%",
            bottom: "3%",
            containLabel: true,
          },
          legend: {
            top: 20,
            textStyle: {
              fontSize: 12,
              color: "#D1D4DC",
            },
            padding: [0, 32, 0, 0],
            itemHeight: 12,
            itemWidth: 12,
            itemGap: 40,
            data: [
              {
                name: "Calls",
                icon: "roundRect",
              },
              {
                name: "Puts",
                icon: "roundRect",
              },
            ],
          },
          xAxis: [
            {
              data: xData,
              type: "category",

              offset: 12,
              axisLabel: {
                rotate: 60,
                textStyle: {
                  color: "rgba(209, 212, 220, 1)",
                },
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              name: "Open Interest",
              axisLabel: {
                formatter: function (v) {
                  if (v > 1000 && v <= 1000000) {
                    return v / 1000 + "K";
                  } else if (v > 1000000 && v <= 1000000000) {
                    return v / 1000000 + "M";
                  } else if (v > 1000000000) {
                    return v / 1000000000 + "B";
                  } else {
                    return v;
                  }
                  // return v + 'K';
                },
                textStyle: {
                  color: "rgba(209, 212, 220, 1)",
                },
                margin: 20,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                  color: "rgba(159, 159, 159, 0.1)",
                },
              },
            },
            {
              type: "value",
              show: false,
            },
          ],
          series: [
            {
              name: "Calls",
              type: "bar",
              data: callSet,
              emphasis: {
                focus: "series",
              },
              itemStyle: {
                color: "rgba(74, 181, 232, 1)",
              },
            },
            {
              name: "Puts",
              type: "bar",
              data: putSet,
              emphasis: {
                focus: "series",
              },
              itemStyle: {
                color: "rgba(234, 204, 174, 1)",
              },
            },
          ],
          graphic: [
            {
              type: "image",
              id: "logo",
              right: "center",
              bottom: "center",
              z: 0,
              bounding: "all",
              style: {
                image: "echarts-bg_yYgTFo1.png", // 必须是https开头的图片路径地址
                width: 400,
                height: 68,
              },
            },
          ],
        };
      },
      // getstageData() {
      //   const params = {
      //     type: "公告",
      //   };
      //   //console.log(params);
      //   this.axios
      //     .get("http://192.168.2.68:8000/games/noticeedit/", { params })
      //     .then((res) => {
      //       // 处理响应
      //       //console.log("测试", res);
      //     })
      //     .catch((error) => {
      //       // 处理错误
      //       console.error(error);
      //     });
      // },
      //奇偶行背景色不同
      tableRowClassName({ rowIndex }) {
        if ((rowIndex + 1) % 2 === 0) {
          return "oddRow";
        }

        return "evenRow";
      },
      //表格内容添加千位分割符/货币格式
      matter(row, column, cellValue) {
        cellValue += "";
        if (!cellValue.includes(".")) cellValue += ".";
        return cellValue
          .replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
            return $1 + ",";
          })
          .replace(/\.$/, "");
      },
      //请求brr数据的接口，获取数据。第一个数据是数组  放到brrData。第二个是对象 直接取
      getBrData() {
        this.axios
          .get("https://www.cmegroup.com/services/cryptocurrencies/reference-rates")
          .then((res) => {
            //brr得数据全部放入数组变量，一会展示直接用数组下标取

            this.brrData = res.data.referenceRates.BRR;
            // this.brtiData = res.data.referenceRates.BRTI[0];
          })
          .catch((e) => {
            // console.log(e);
          });
      },

      //处理日期显示
      formatDateShow(date) {
        let dateStr = new Date(date).toString();
        return `${dateStr.split(" ")[2]} ${dateStr.split(" ")[1]}`;
      },

      //使用 WebSocket 实时获取BRTI数据信息
      initWebSocket() {
        this.websock = null;
        //判断当前浏览器是否支持WebSocket
        if ("WebSocket" in window) {
          //初始化weosocket(必须)
          this.websock = new WebSocket("wss://crypto.cmegroup.com/"); //新建一个webstock对象
          this.websock.onopen = this.websocketonopen;
          this.websock.onmessage = this.websocketonmessage;
          this.websock.onerror = this.websocketonerror;
          this.websock.onclose = this.websocketclose;
        } else {
          alert("当前浏览器 Not support websocket");
        }
      },
      websocketonopen() { },
      websocketonerror() {
        //连接建立失败重连
        this.initWebSocket();
      },
      websocketonmessage(e) {
        //数据接收
        let dataObj = JSON.parse(e.data);
        if (dataObj.symbol === "BRTI") {
          this.brtiData = dataObj;
        }
      },
      websocketsend(Data) {
        //数据发送
        this.websock.send(Data);
      },
      websocketclose(e) {
        //关闭
        // console.log("断开连接", e);
      },

      //获取两个合约的交易所,进行去重
      getBourseName(arr) {
        let uMarginData = [];
        let cMarginData = [];
        uMarginData = arr[0].uMarginList.map((v) => v.exchange_name);
        cMarginData = arr[0].cMarginList.map((v) => v.exchange_name);
        const finalData = Array.from(new Set([...cMarginData, ...uMarginData]));
        //返回一个去重后得数组
        return [...finalData];
      },
      //处理图表数据，通过type值筛选BTC或者ETH
      getCurrencyChartData(type) {
        //获取BTC得全部数据
        let tempData = this.initChartData.filter(
          (v) => v.currency_name === type
        )[0];

        // console.log("tempData", tempData);
        let cArr = [0, 0, 0, 0, 0, 0];
        let uArr = [0, 0, 0, 0, 0, 0];
        // console.log(111, type);
        //处理c和u
        tempData.cMarginList.forEach((v, i) => {
          let findIndex = this.exchangeData.indexOf(v.exchange_name);
          cArr[findIndex] = v.last_funding_rate;
        });
        tempData.uMarginList.forEach((v, i) => {
          let findIndex = this.exchangeData.indexOf(v.exchange_name);
          uArr[findIndex] = v.last_funding_rate;
        });
        let finalcArr = cArr.map((v) => (+v * 100).toFixed(4));
        let finaluArr = uArr.map((v) => (+v * 100).toFixed(4));
        return [finalcArr, finaluArr];
      },
      //获取Binance的所有币种
      getCurrencyName() {
        let currencyData = [];
        this.initChartData.forEach((item) => {
          item.uMarginList.forEach((item) => {
            if (item.exchange_name === "Binance") {
              currencyData.push(item.currency_name);
            }
          });
        });
        return currencyData;
      },

      // 获取Binance的所有数据
      getBinanceData() {
        let fundingRateData = [];
        this.initChartData.forEach((item) => {
          item.uMarginList.forEach((item) => {
            if (item.exchange_name === "Binance") {
              fundingRateData.push(item.last_funding_rate);
            }
          });
        });
        let finalufundingRateArr = fundingRateData.map((v) =>
          (+v * 100).toFixed(4)
        );

        return finalufundingRateArr;
      },
      //对Binance的所有数据进行排序
      sortBinanceData() {
        //对象数组
        let objArr = [];
        //装最后处理好的币种数据
        let currencyData = [];
        //装最后处理好的数值数据
        let fundingRateData = [];
        //遍历，构建成对象放进数组
        this.binanceData.forEach((v, i) => {
          let obj = {
            name: v,
            value: +this.finalufundingRateArr[i],
          };
          objArr.push(obj);
        });
        //排序
        objArr.sort((a, b) => {
          return b.value - a.value;
        });
        //取出排序好得数据
        this.binanceData = objArr.map((v) => v.name);
        this.finalufundingRateArr = objArr.map((v) => v.value);
      },

      //请求全部资金费率图表数据
      getRateMap() {
        getFundsRate()
          .then((res) => {
            let resData = res.data.data;
            //初始数据放到data ，以便其他地方处理
            this.initChartData = resData;
            // console.log("resData", resData);
            // this.getBourseName(resData);
            //如果是要用返回值，这里将返回值赋值给data得变量
            this.exchangeData = this.getBourseName(resData);
            // console.log("exchangeData", this.exchangeData);

            this.btcData = this.getCurrencyChartData("BTC");
            this.ethData = this.getCurrencyChartData("ETH");
            // console.log(this.btcData, this.ethData);

            this.binanceData = this.getCurrencyName("Binance");
            this.finalufundingRateArr = this.getBinanceData("Binance");
            this.sortBinanceData();

            this.dealEchartData();
          })
          .catch((err) => {
            //console.log(err);
          });
      },

      dealEchartData() {
        this.$refs.chart1.chart.setOption({
          title: {
            text: "BTC",
            textStyle: {
              color: "#ffffff",
            },
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          legend: {
            show: false,
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "value",
            boundaryGap: [0, 0.01],
            axisLabel: {
              textStyle: {
                color: "#FFF",
              },
            },
            splitLine: {
              //隐藏网格线
              show: false,
            },
          },
          yAxis: {
            type: "category",
            data: this.exchangeData.reverse(),
            axisLabel: {
              textStyle: {
                color: "#FFF",
              },
            },
          },
          series: [
            {
              name: "反向合约",
              type: "bar",
              data: this.btcData[0].reverse(),

              markLine: {
                silent: true,
                label: {
                  show: false,
                },
                symbol: "none", //去掉箭头
                data: [
                  {
                    xAxis: 0.01,
                    lineStyle: {
                      color: "rgb(98,86,238)",
                    },
                  },
                ],
              },

              itemStyle: {
                normal: {
                  color: function (params) {
                    var index_color = params.value;
                    if (index_color > 0.01) {
                      return "#ea5455";
                    } else if (index_color == 0.01) {
                      return "rgb(98,86,238)";
                    } else {
                      return "#28c76f";
                    }
                  },
                },
              },
            },
            {
              name: "正向合约",
              type: "bar",
              data: this.btcData[1].reverse(),
              markLine: {
                silent: true,
                label: {
                  show: false,
                },
                symbol: "none", //去掉箭头
                data: [
                  {
                    xAxis: 0.01,
                    lineStyle: {
                      color: "rgb(98,86,238)",
                    },
                  },
                ],
              },
              itemStyle: {
                normal: {
                  color: function (params) {
                    var index_color = params.value;
                    if (index_color > 0.01) {
                      return "#ea5455";
                    } else if (index_color == 0.01) {
                      return "rgb(98,86,238)";
                    } else {
                      return "#28c76f";
                    }
                  },
                },
              },
            },
          ],
        });
        this.$refs.chart2.chart.setOption({
          title: {
            text: "ETH",
            textStyle: {
              color: "#ffffff",
            },
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          legend: {
            show: false,
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: {
            type: "value",
            boundaryGap: [0, 0.01],
            axisLabel: {
              textStyle: {
                color: "#FFF",
              },
            },
            splitLine: {
              //隐藏网格线
              show: false,
            },
          },
          yAxis: {
            type: "category",
            data: this.exchangeData,
            axisLabel: {
              textStyle: {
                color: "#FFF",
              },
            },
          },
          series: [
            {
              name: "反向合约",
              type: "bar",
              data: this.ethData[0].reverse(),
              markLine: {
                silent: true,
                label: {
                  show: false,
                },
                symbol: "none", //去掉箭头
                name: "none",
                data: [
                  {
                    xAxis: 0.01,
                    lineStyle: {
                      color: "#999",
                    },
                  },
                ],
                label: {
                  show: false,
                },
              },
              itemStyle: {
                normal: {
                  color: function (params) {
                    var index_color = params.value;
                    if (index_color > 0.01) {
                      return "#ea5455";
                    } else if (index_color == 0.01) {
                      return "rgb(98,86,238)";
                    } else {
                      return "#28c76f";
                    }
                  },
                },
              },
            },
            {
              name: "正向合约",
              type: "bar",
              data: this.ethData[1].reverse(),
              markLine: {
                silent: true,
                label: {
                  show: false,
                },
                symbol: "none", //去掉箭头
                data: [
                  {
                    xAxis: 0.01,
                    lineStyle: {
                      color: "rgb(98,86,238)",
                    },
                  },
                ],
              },
              itemStyle: {
                normal: {
                  color: function (params) {
                    var index_color = params.value;
                    if (index_color > 0.01) {
                      return "#ea5455";
                    } else if (index_color == 0.01) {
                      return "rgb(98,86,238)";
                    } else {
                      return "#28c76f";
                    }
                  },
                },
              },
            },
          ],
        });
        // console.log("this.finalufundingRateArr", this.finalufundingRateArr);
        this.$refs.chart3.chart.setOption({
          title: {
            text: "Binance",
            textStyle: {
              color: "#ffffff",
            },
          },
          yAxis: {
            axisLabel: {
              textStyle: {
                color: "#FFF",
              },
            },
          },
          xAxis: {
            data: this.binanceData,
            max: 20, // 最多显示20个币种
            scale: true,
            axisLabel: {
              textStyle: {
                color: "#FFF",
              },
            },
          },
          dataZoom: [
            {
              type: "slider",
              show: true,
              start: 0,
              end: 100,
              handleSize: 2,
            },
            {
              type: "inside",
              start: 0,
              end: 100,
            },
          ],
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          legend: {
            show: false,
          },
          series: [
            {
              name: "预测资金费率",
              type: "bar",
              data: this.finalufundingRateArr,
              barCategoryGap: "0%", //柱形间距
              itemStyle: {
                normal: {
                  color: function (params) {
                    var index_color = params.value;
                    if (index_color > 0.01) {
                      return "#ea5455";
                    } else if (index_color == 0.01) {
                      return "rgb(98,86,238)";
                    } else {
                      return "#28c76f";
                    }
                  },
                },
              },
              markPoint: {
                symbolSize: "40", //标记图例大小
                data: [
                  // {
                  //   coord: ['ETH', '0.03'], name: 'BTC'
                  // }
                  {
                    name: "BTC",
                    value: "BTC",
                    xAxis: "BTC",
                    yAxis: this.getBTCdata,
                  },
                  {
                    name: "ETH",
                    value: "ETH",
                    xAxis: "ETH",
                    yAxis: this.getETHdata,
                  },
                ],
              },
            },
          ],
        });
      },

      getListData() {
        let params = {
          p: this.pageParams.currentPage,
          l: this.pageParams.pageSize,
        };
        getBtcOptionsRate(params)
          .then((res) => {
            if (res.data.code == "0") {
              // console.log(res);
              let tempData = res.data.data;
              this.tableData = tempData;
            }
          })
          .catch((err) => {
            // console.log(err);
          });
      },
    },
  };
</script>
<style lang="scss">
  @import "./scss/home/home.scss";
</style>