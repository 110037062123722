import request from "@/utils/request"
//获取大宗交易deribit BTC期权数据
export function getBtcOptionsRate(data) {
    return request({
        method: 'get',
        url: '/deribitOptionTrades?bc=BTC',
        params: data,

    })

}
//请求期权Deribit ETH期权数据
export function getEthOptionsRate(data) {
    return request({
        method: 'get',
        url: '/deribitOptionTrades?bc=ETH',
        params: data,
    })

}

//获取大宗交易bybit期货数据
export function getFuturesRate(data) {
    return request({
        method: 'get',
        url: '/',
        params: data,

    })

}