<template>
  <div class="chart" ref="chart" :style="{ height: height, width: width }">
    <!-- 使用prop控制加载动画 -->
    <div v-if="loading" class="echarts-loading"></div>
  </div>
</template>

<script>
const defaultOpts = {
  notMerge: true,
  lazyUpdate: true,
};

export default {
  name: "Chart",
  data() {
    return {
      chart: null,
    };
  },
  props: {
    option: {
      type: Object,
      default() {
        return {
          toolbox: {
            show: true,
            feature: {
              restore: {
                show: true,
                title: "Restore",
              },
            },
          },
          noMerge: true,
          // 其他配置项...
        };
      },
    },
    loading: {
      // 新增loading prop
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "400px",
    },
  },
  watch: {
    option() {
      if (this.chart) {
        this.chart.setOption(this.option, defaultOpts); // 设置对应配置项
      }
    },
    loading(newValue) {
      if (this.chart) {
        // 根据loading值来切换ECharts内置的loading状态
        newValue ? this.chart.showLoading() : this.chart.hideLoading();
      }
    },
  },
  mounted() {
    this.chart = this.$echarts.init(this.$refs.chart); // 初始化echarts
    this.chart.setOption(this.option, defaultOpts); // 设置对应配置项
    this.loading &&
      this.chart.showLoading({
        // 显示ECharts自带的加载动效，并设置背景为透明
        text: "Loading...",
        color: "#EFC394 ",
        textColor: "#000",
        maskColor: "rgba(255, 255, 255, 0)",
        zlevel: 0,
      }); // 如果初始化时loading为true，则显示加载动态效果
    // 添加restore事件监听器
    this.chart.on("restore", () => {
      // 重新设置图表的配置项
      this.chart.setOption(this.option, defaultOpts);
    });
    window.addEventListener("resize", this.chart.resize);
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
      window.removeEventListener("resize", this.chart.resize);
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
