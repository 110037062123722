<template>
  <div
    class="chart"
    ref="chart"
    :style="{ height: height, width: width }"
  ></div>
</template>

<script>
export default {
  name: "Chart",
  data() {
    return {
      chart: null,
    };
  },
  props: {
    // 传配置项option
    option: {
      type: Object,
      default() {
        return {
          elementResize: "",
          toolbox: {
            show: true,
            feature: {
              restore: {
                show: true,
                title: "Restore",
              },
            },
          },
        };
      },
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "400px",
    },
  },
  watch: {
    option() {
      if (this.chart) {
        this.chart.setOption(this.option); // 设置对应配置项
      }
    },
  },
  mounted() {
    this.chart = this.$echarts.init(this.$refs.chart); // 初始化echarts
    this.chart.setOption(this.option); // 设置对应配置项
    // 添加restore事件监听器
    this.chart.on("restore", () => {
      // 重新设置图表的配置项
      this.chart.setOption(this.option);
    });
    this.chart.on("ready", function () {
      this.chart.getZr().painter.getViewportRoot().position[1] += 20; // 调整上边距
      this.chart.resize();
    });

    window.addEventListener("resize", this.chart.resize);

    // 添加鼠标悬停事件监听器
    this.chart.on("mouseover", (params) => {
      // 根据系列名称，高亮对应的数据
      const seriesName = params.seriesName;
      this.chart.dispatchAction({
        type: "highlight",
        seriesName: seriesName,
        dataIndex: params.dataIndex,
      });
    });

    // 添加鼠标移出事件监听器
    this.chart.on("mouseout", () => {
      // 取消高亮
      this.chart.dispatchAction({
        type: "highlight",
        seriesName: "a",
        dataIndex: -1, // 取消高亮
      });
      this.chart.dispatchAction({
        type: "highlight",
        seriesName: "b",
        dataIndex: -1, // 取消高亮
      });
    });
  },
};
</script>

<style lang="scss" scoped>
</style>

