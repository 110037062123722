import axios from 'axios' //引入axios
const service = axios.create({    // api 的 baseUrl 就是每个请求前面相同的地址，这个地方配置了环境，直接取环境里的配置
    //baseURL: "http://47.242.11.83/api/v1",//测试数据
    // baseURL: "http://api.rta.com/api/v1",//内部测试api
    baseURL: "https://api.rta.trade/api/v1",//生产环境数据
    //baseURL: "http://192.168.5.10:8000/api/v1",
    timeout: 10000 // 请求超时时间
})
// request拦截器
service.interceptors.request.use(
    config => {
        //这里是每个请求的设置，比如每个请求都携带一个token，或者当为post请求时，引入qs格式化参数。
        return config
    },
    error => {
        console.log('请求失败:' + error)
        Promise.reject(error)
    }
)
// response 拦截器
service.interceptors.response.use(
    response => {
        if (response.code !== '0') {
            //在此可以加几种比较普遍的设置，然后可以把单独的返到页面中设置
            if (response.data.code == '0') {
                return Promise.resolve(response)
            }
            return Promise.reject(response)

        } else {
            return Promise.resolve(response)
        }
    },
    error => {
        console.log('响应失败:' + error)
        return Promise.reject(error)
    }
)
export default service

